import { entities, enums, formatters } from "@fraction/shared";
import { formatRelative } from "date-fns";
import { ChevronDown, ChevronUp, Loader } from "lucide-react";
import { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ReadyState } from "react-use-websocket";
import { useAuth } from "src/auth";
import Avatar from "src/components/Avatar";
import { AlwaysScrollToLastChild } from "src/components/ScrollOnMount";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useCachedState } from "src/hooks/useCache";
import { useChat } from "src/hooks/useChat";
import { cn } from "src/utilities/shadcnUtils";

const chatColor = {
  [enums.ChatType.BROKER_EMPLOYEE]: "bg-green",
  [enums.ChatType.LENDER_EMPLOYEE]: "bg-blue",
  [enums.ChatType.CONVEYANCER_EMPLOYEE]: "bg-purple",
  [enums.ChatType.LENDER_CONVEYANCER]: "bg-purple",
};

export interface AppChatHandle {
  setText: (text: string) => void;
  focus: () => void;
}

export const AppChat = forwardRef<
  AppChatHandle,
  {
    applicationId?: string;
    className?: string;
    expandedClassName?: string;
    textBoxClassName?: string;
    type: enums.ChatType;
    enableChatOnFocusOnly?: boolean;
    initialData?: entities.Chat[];
  }
>(function AppChat(
  {
    applicationId,
    className,
    expandedClassName,
    textBoxClassName,
    type,
    enableChatOnFocusOnly = false,
    initialData,
  },
  ref
) {
  const { preference } = useAccountViewPreference();
  const { user } = useAuth();
  const [isFocused, setIsFocused] = useState(false);
  const { chats, members, sendMessage, isPending, readyState } = useChat({
    applicationId,
    type,
    enabled: enableChatOnFocusOnly ? isFocused : true,
    initialData,
  });
  const [text, setText] = useState("");
  const [expanded, setExpanded] = useCachedState(
    true,
    `app-chat-expanded-${type}-${enableChatOnFocusOnly ? applicationId : ""}`
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      setText: (newText: string) => {
        setText(newText);
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      },
      focus: () => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      },
    }),
    []
  );

  const chatTitle = `Chat with ${type
    ?.replace(preference, "")
    .replace("_", "")
    .replaceAll("_", " and ")
    .replace("employee", "Fraction")} about this deal`;

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, []);

  const handlePressSend = useCallback(
    (e?: any) => {
      e?.preventDefault?.();
      const textToSend = text;
      setText("");

      if (!applicationId) {
        return;
      }
      return sendMessage({ message: textToSend, applicationId });
    },
    [text, applicationId, sendMessage]
  );

  const handleToggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  if (!applicationId) {
    return null;
  }

  if (!expanded) {
    return (
      <button
        onClick={handleToggleExpanded}
        className={cn(
          "w-[355px] py-2 rounded-t items-center flex justify-between text-white text-sm gap-1 px-4 text-center",
          className,
          preference === "employee" ? chatColor[type] : "bg-green"
        )}
      >
        <div className="flex flex-col gap-[1px] align-start text-left">
          <div className="flex flex-row gap-1 items-center">
            {readyState === ReadyState.OPEN ? (
              <div className="w-2 h-2 min-w-2 min-h-2 border border-white bg-green rounded-full" />
            ) : (
              <div className="w-2 h-2 min-w-2 min-h-2 border border-white bg-red rounded-full" />
            )}
            <p>{chatTitle}</p>
          </div>
          <p className="text-xs">
            {members
              ?.map((member) => formatters.user.userName(member).trim())
              .filter(Boolean)
              .join(", ")}
          </p>
        </div>
        <ChevronUp height={20} />
      </button>
    );
  }

  return (
    <div className={cn("bg-white rounded w-[355px] flex flex-col", className, expandedClassName)}>
      <button
        onClick={handleToggleExpanded}
        className={cn(
          "w-[355px] py-2 rounded-t items-center flex justify-between text-white text-sm gap-1 px-4 text-center",
          preference === "employee" ? chatColor[type] : "bg-green"
        )}
      >
        <div className="flex flex-col gap-[1px] align-start text-left">
          <div className="flex flex-row gap-1 items-center">
            {readyState === ReadyState.OPEN ? (
              <div className="w-2 h-2 min-w-2 min-h-2 border border-white bg-green rounded-full" />
            ) : (
              <div className="w-2 h-2 min-w-2 min-h-2 border border-white bg-red rounded-full" />
            )}
            <p>{chatTitle}</p>
          </div>
          <p className="text-xs">{members?.map((member) => formatters.user.userName(member)).join(", ")}</p>
        </div>
        <ChevronDown height={20} />
      </button>
      <AlwaysScrollToLastChild className={cn("h-full p-4 overflow-y-scroll", textBoxClassName)}>
        {chats?.map((chat, chatGroupIdx) => {
          const isLastChatGroup = chatGroupIdx === (chats?.length || 1) - 1;
          const isThisUser = chat.user?.id === user?.id;
          return (
            <div
              className="flex gap-2 flex-row pl-8 pr-4 relative"
              key={`${chat.user?.id}-${chat.chats?.[(chat.chats?.length || 1) - 1]?.id}`}
            >
              {!isThisUser ? (
                <Avatar
                  className="align-end absolute left-[-4px] top-9 w-[26px] h-[26px] text-xs"
                  title={formatters.user.userName(chat.user)}
                >
                  {chat.user?.firstName?.[0]}
                  {chat.user?.lastName?.[0]}
                </Avatar>
              ) : null}
              <div className={cn("flex flex-col gap-3 w-full", isThisUser ? "items-end" : "items-start")}>
                {chat?.chats?.map((message, index) => {
                  const isLastChat = index === (chat?.chats?.length || 1) - 1 && isLastChatGroup;
                  return (
                    <div
                      className={cn("w-full flex flex-col", isThisUser ? "items-end" : "items-start")}
                      key={message.id}
                    >
                      <div className="w-full flex items-center justify-center">
                        {message.createdAt ? (
                          <p className="text-xs text-gray text-center align-middle">
                            {formatters.string.capitalize(formatRelative(message.createdAt, new Date()))}
                          </p>
                        ) : null}
                      </div>
                      <div className="flex flex-col max-w-[60%] w-fit">
                        <p
                          className={cn(
                            "text-[8px] text-gray",
                            isThisUser ? "text-right translate-x-[-16px]" : "text-left translate-x-[16px]"
                          )}
                        >
                          {message.user?.firstName}
                        </p>
                        <div className="flex flex-row gap-1 items-center">
                          <div
                            className={cn(
                              "flex flex-col text-start p-2 px-3 rounded-[8px] text-xs",
                              isThisUser ? "bg-green-400" : "bg-gray-400"
                            )}
                          >
                            {message.message?.split("\n").map((x) => (
                              <p key={x}>{x}</p>
                            ))}
                          </div>
                          {isLastChat && isPending && (
                            <Loader height={16} className="text-gray-600 animate-spin" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </AlwaysScrollToLastChild>
      <form onSubmit={handlePressSend} className="w-full flex flex-row gap-2 px-4 p-2 border items-center">
        <TextareaAutosize
          maxRows={10}
          ref={textareaRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handlePressSend();
              setText("");
            }
          }}
          onChange={handleChange}
          value={text}
          className="rounded-[8px] outline-green focus:outline outline-offset-0 text-sm text-left bg-gray-400 py-2 px-5 w-full min-w-auto"
        />
        <button
          type="submit"
          className="bg-green rounded-full text-sm text-white w-20 flex items-center justify-center h-fit py-2"
          onClick={handlePressSend}
        >
          Send
        </button>
      </form>
    </div>
  );
});
